import React, { useRef, useState, useEffect } from "react";
import Button from "./Button.js";
import ThreeContainer from "../Rocket";
import Stars from "./Stars";
import { useInView } from "react-intersection-observer";
import cl from "classnames";

const ContactForm = () => {
  const [isSent, setIsSent] = useState(false);
  const emailRef = useRef();
  const ideaRef = useRef();
  const emailStruct = (email, idea) => {
    return `Datos de contacto \nEmail:${email} \nIdea:${idea}`;
  };
  const sendMail = (ev) => {
    ev.preventDefault();
    const email = emailRef.current.value.trim().toLowerCase();
    const idea = ideaRef.current.value.trim();
    if (email.length < 8 || idea.length < 5) {
      alert('Los campos "correo" e "idea" no pueden ir vacíos.');
      return;
    }
    if (isSent) return;
    const emailBody = emailStruct(email, idea);
    var myHeaders = new Headers();
    myHeaders.append("api-key", "outlierweb-diz1yRFXoMLz0tVP795KltAtS2z5aT");
    myHeaders.append("origin", "https://outlier.cl");
    myHeaders.append("subject", "prueba de email");
    myHeaders.append("destination", "contacto@outlier.cl");
    myHeaders.append("Content-Type", "text/plain");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: emailBody,
      redirect: "follow",
    };

    fetch(
      "https://emailsender.microservices.outlier.cl/api/sendemail",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => setIsSent(true))
      .catch((error) => console.log("error", error));
  };

  const { ref, inView, entry } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (!entry) return;
    window.anime.timeline({ loop: false }).add({
      targets: "#contact .section-title .letter",
      translateX: [40, 0],
      translateZ: 0,
      opacity: inView ? [0, 1] : [1, 0],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (_el, i) => 350 + 40 * i,
    });
  }, [inView]);
  const className = cl("contact-form", { "in-view": inView });
  return (
    <>
      <div ref={ref} id="contact" className={className}>
        <div className="contact-form-content">
          <div className="left-section">
            <h3 className="section-name">¡Hablemos!</h3>
            <h4 className="section-title">
              <span className="letter">Ya</span>{" "}
              <span className="letter">sabes</span>{" "}
              <span className="letter">un</span>{" "}
              <span className="letter">poco</span>{" "}
              <span className="letter">de</span>{" "}
              <span className="letter" style={{ color: "#05ABE8" }}>
                nosotros
              </span>{" "}
              <span className="letter">ahora</span>{" "}
              <span className="letter">queremos</span>{" "}
              <span className="letter">saber</span>{" "}
              <span className="letter" style={{ color: "#05ABE8" }}>
                más
              </span>{" "}
              <span className="letter" style={{ color: "#05ABE8" }}>
                de
              </span>{" "}
              <span className="letter" style={{ color: "#05ABE8" }}>
                tí.
              </span>
            </h4>
            <div className="inputs">
              <label htmlFor="email-input">Tu correo </label>
              <input
                ref={emailRef}
                id="email-input"
                type="email"
                placeholder="Tu correo"
              />
              <label htmlFor="msg-input">Cuéntanos tu idea </label>
              <textarea
                id="msg-input"
                name="Mensaje"
                ref={ideaRef}
                cols="30"
                rows="10"
                placeholder="Tu idea"
              ></textarea>
            </div>
            <Button
              disabled={isSent}
              className="button-default"
              label={isSent ? "Mensaje Enviado" : "Enviar Mensaje"}
              onClick={sendMail}
            />
          </div>
          <div className="right-section">
            <Stars></Stars>
            <ThreeContainer></ThreeContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
