import smoth from "smoothscroll-polyfill";

smoth.polyfill();

export function scrollTo() {
  const links = document.querySelectorAll(".scroll");
  console.log(links);
  links.forEach((each) => (each.onclick = scrollAnchors));
}

export function scrollAnchors(e, respond = null) {
  const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);

  var targetID = respond
    ? respond.getAttribute("href")
    : this.getAttribute("href");
  if (!targetID.startsWith("#")) return;
  e.preventDefault();
  const targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  const originalTop = distanceToTop(targetAnchor);
  window.scrollBy({ top: originalTop || 0, left: 0, behavior: "smooth" });
  const checkIfDone = setInterval(function () {
    const atBottom =
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (distanceToTop(targetAnchor) === 0 || atBottom) {
      targetAnchor.tabIndex = "-1";
      targetAnchor.focus();
      window.history.pushState("", "", targetID);
      clearInterval(checkIfDone);
    }
  }, 100);
}
