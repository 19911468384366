import React, { useEffect } from "react";
import Button from "./Button.js";

const HeroSection = ({ onExplore, isExploring }) => {
  useEffect(() => {
    // Wrap every letter in a span
    var textWrapper = document.querySelector(".section-title");

    window.anime.timeline({ loop: false }).add({
      targets: ".hero .section-title .letter",
      translateX: [40, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (el, i) => 1000 + 40 * i,
    });
  }, []);

  return (
    <>
      <div className="hero">
        <div className="hero-content">
          <h1 className="section-title">
            <span className="letter">Soluciones</span>{" "}
            <span className="letter" style={{ color: "#05ABE8" }}>
              innovadoras
            </span>{" "}
            <span className="letter">a</span>{" "}
            <span className="letter">problemas</span>{" "}
            <span className="letter" style={{ color: "#05ABE8" }}>
              únicos
            </span>{" "}
          </h1>
          <h2 className="hero-sub">
            Te ayudamos a hacer realidad tu idea o proyecto de la manera más
            innovadora.
          </h2>
        </div>
      </div>
      <div className="hero-bottom">
        <Button
          onClick={onExplore}
          className="button-white"
          label={`Click para ${isExploring ? "salir" : "explorar"}`}
        ></Button>
      </div>
    </>
  );
};

export default HeroSection;
