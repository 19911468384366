import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useEffect, useRef, useState } from "react";
import {
  vertex as bandVertexShader,
  fragment as bandFragmentShader,
} from "./assets/shaders/fire";

let cubeTextureLoader = null;
let materialBand = null;

/**Texture variables */
let redMatcap,
  whiteMatcap,
  yellowMatcap,
  glassMatcap,
  grayMatcap,
  particlesMatcap,
  bandTexture;

/**Material variables */
let redMaterial,
  whiteMaterial,
  yellowMaterial,
  glassMaterial,
  grayMaterial,
  particlesMaterial;

function loadTextures() {
  const textureLoader = new THREE.TextureLoader();
  bandTexture = textureLoader.load("./static/textures/band.png");
  redMatcap = textureLoader.load("./static/textures/matcaps/red.png");
  whiteMatcap = textureLoader.load("./static/textures/matcaps/white.png");
  yellowMatcap = textureLoader.load("./static/textures/matcaps/yellow.png");
  glassMatcap = textureLoader.load("./static/textures/matcaps/glass.png");
  grayMatcap = textureLoader.load("./static/textures/matcaps/gray.png");
  particlesMatcap = textureLoader.load(
    "./static/textures/matcaps/particles.png"
  );
}

/**
 * Funcion para cargar todos los materiales
 */

function loadMaterials() {
  redMaterial = new THREE.MeshMatcapMaterial();
  redMaterial.matcap = redMatcap;

  whiteMaterial = new THREE.MeshMatcapMaterial();
  whiteMaterial.matcap = whiteMatcap;

  yellowMaterial = new THREE.MeshMatcapMaterial();
  yellowMaterial.matcap = yellowMatcap;

  glassMaterial = new THREE.MeshMatcapMaterial();
  glassMaterial.matcap = glassMatcap;

  grayMaterial = new THREE.MeshMatcapMaterial();
  grayMaterial.matcap = grayMatcap;

  particlesMaterial = new THREE.MeshMatcapMaterial();
  particlesMaterial.matcap = particlesMatcap;
}

/**
 * Funcion para cargar todos los shaders
 */
function loadShaders() {
  // const leafShader = {
  //   vertexShader: leafVertexShader,
  //   fragmentShader: leafFragmentShader,
  //   side: THREE.DoubleSide,
  // };
  // materialLeaf = new THREE.ShaderMaterial({
  //   ...leafShader,
  //   uniforms: {
  //     uFrequency: { value: new THREE.Vector2(10, 5) },
  //     uTime: { value: 0 },
  //     uTexture: { value: leafTexture },
  //   },
  // });
  const bandShader = {
    vertexShader: bandVertexShader,
    fragmentShader: bandFragmentShader,
    side: THREE.DoubleSide,
  };
  materialBand = new THREE.ShaderMaterial({
    ...bandShader,
    uniforms: {
      uFrequency: { value: new THREE.Vector2(10, 5) },
      uTime: { value: 0 },
      uTexture: { value: bandTexture },
    },
  });
}
function configuration({ scene, camera }) {
  // gui = new dat.GUI();
  /** Environment map */
  cubeTextureLoader = new THREE.CubeTextureLoader();
  camera.position.set(-4.48, -0.66, -8.5);
  camera.rotation.set(2.9, -0.4, 3);
  scene.add(camera);
}

// ____________________ INIT _____________________________
function ThreeComponent() {
  let mixers = [];
  let renderer = null;
  let scene = new THREE.Scene();
  let gltfLoader = new GLTFLoader();
  let controls = null;
  let camera = null;
  let aspect = 2; // width / height
  let fov = 15;
  let near = 1;
  let far = 100;
  let game = [];

  camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const canvasRef = useRef();
  useEffect(() => {
    if (canvasRef.current === null) return;
    const localSizes = {
      w: canvasRef.current.getBoundingClientRect().width,
      h: canvasRef.current.getBoundingClientRect().height,
    };
    sizes.width = localSizes.w;
    sizes.height = localSizes.h;

    /** Textures */
    loadTextures();

    /** Materials*/
    loadMaterials();

    /** Shader Materials */
    loadShaders();

    /**Configuracion escena */

    /** Configuracion general */
    configuration({ scene, camera });

    /**
     * Renderer
     */
    renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: true,
      alpha: true,
    });

    // ---------------------------------------------------------

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.physicallyCorrectLights = true;
    renderer.outputEncoding = THREE.sRGBEncoding;

    /** Models */
    loadModel({ scene, gltfLoader, mixers, game });
    renderer.setAnimationLoop((props) =>
      tick({ ...props, renderer, scene, camera, mixers, game })
    );
    return () => {
      scene = null;
      camera = null;
      controls = null;
      renderer.setAnimationLoop(null);
    };

    // Scene white
  }, [canvasRef.current]);

  return (
    <canvas
      ref={canvasRef}
      className="webgl"
      style={{ height: "100%", width: "100%" }}
    ></canvas>
  );
}

// ____________________ FIN INIT _________________________

function resizeRendererToDisplaySize(renderer) {
  const canvas = renderer.domElement;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}

/**
 * Carga el modelo
 */
function loadModel({ scene, gltfLoader, mixers, game }) {
  const outlierModelPath = "./static/models/rocket.glb";

  gltfLoader.load(outlierModelPath, (gltf) => {
    gltf.scene.scale.set(0.9, 0.9, 0.9);
    gltf.scene.position.set(-1, -0.2, 0);
    gltf.scene.rotation.y = Math.PI * 0.7;

    const cube = gltf.scene.children[0];
    cube.rotation.x = -0.75;
    cube.position.y = cube.position.y - 0.25;
    cube.position.x = cube.position.x - 1;
    cube.position.z = cube.position.z - 1;

    const rocket = cube.children[0];
    rocket.material = redMaterial;
    game.push(rocket);
    game.push(cube);
    for (let index = 0; index < rocket.children.length; index++) {
      if (rocket.children[index].name === "rocket-yellow")
        rocket.children[index].material = yellowMaterial;

      if (rocket.children[index].name === "rocket-gray")
        rocket.children[index].material = grayMaterial;

      if (rocket.children[index].name === "rocket-glass")
        rocket.children[index].material = glassMaterial;

      if (rocket.children[index].name === "rocket-white")
        rocket.children[index].material = whiteMaterial;

      if (rocket.children[index].name === "rocket-particle")
        rocket.children[index].material = materialBand;
    }
    gltf.scene.traverse(function (object) {
      object.frustumCulled = false;
    });
    scene.add(gltf.scene);
  });
}

var clock = new THREE.Clock();

let previousTime = 0;

const tick = ({ renderer, scene, camera, mixers, game }) => {
  const elapsedTime = clock.getElapsedTime();

  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }

  // Update material
  materialBand.uniforms.uTime.value = elapsedTime;

  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  if (game.length > 0) {
    for (let index = 0; index < game.length; index++) {
      game[index].rotation.y = Math.sin(elapsedTime * 0.5) * 0.4;
      game[index].position.x = Math.sin(elapsedTime * 20) * 0.01;

      // if (game[index].children && game[index].children.length > 0) {
      //   game[index].children.forEach((child) => {
      //     child.rotation.y = 1 * elapsedTime;
      //   });
      // }
    }
  }
  // Render
  renderer.render(scene, camera);
};

export default ThreeComponent;

export const ThreeContainer = () => {
  const [dimension, setDimension] = useState({
    w: window.innerWidth,
    h: window.innerHeight,
  });
  const divref = useRef();
  useEffect(() => {
    if (divref.current === null) return;

    setDimension({
      w: divref.current.getBoundingClientRect().width,
      h: divref.current.getBoundingClientRect().height,
    });
  }, [divref.current]);

  return (
    <div ref={divref} style={{ height: "100%" }}>
      <ThreeComponent width={dimension.w} height={dimension.h} />
    </div>
  );
};
