import React from "react";
import Card from "./Card.js";
import servicesCard from "./data/servicesCard";

const Grid = () => {
  return (
    <>
      <div className="grid">
        {servicesCard.map((project, index) => {
          return <Card key={index} {...project}></Card>;
        })}
      </div>
    </>
  );
};

export default Grid;
