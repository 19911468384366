import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import cl from "classnames";
import squares from "../assets/img/faces02.png";

const AboutMining = () => {
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (!entry && !inView) return;
    window.anime.timeline({ loop: false }).add({
      targets: "#aboutus .section-title .letter",
      translateX: [40, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (_el, i) => 350 + 40 * i,
    });
  }, [inView]);

  const className = cl("about-mining", { "in-view": inView });
  return (
    <>
      (
      <>
        <div ref={ref} id="aboutus" className={className}>
          <div className="about-mining-content">
            <div className="left-section">
              <img
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="400"
                className="contact-title"
                className="faces"
                src={squares}
              ></img>
            </div>
            <div className="right-section">
              <h3 className="section-name">¿Quiénes Somos?</h3>
              <h4 className="section-title">
                <span className="letter">Nosotros</span>{" "}
                <span className="letter">y</span>{" "}
                <span className="letter">la</span>{" "}
                <span className="letter" style={{ color: "#FFB116" }}>
                  minería
                </span>
              </h4>
              <p className="section-description">
                Somos una empresa que consolida su trayectoria desarrollando
                software principalmente para el sector minero-industrial.
                Llevamos más de 6 años trabajando con la empresa minera BHP,
                ofreciendo soluciones modernas e innovadoras a problemas de
                análisis y visualización de datos, reportabilidad de turnos y
                modernización de procesos análogos.
              </p>
            </div>
          </div>
        </div>
      </>
      );
      {/* <div id="aboutus" className="about-mining">
        <div className="about-mining-content">
          <div className="left-section">
            <h3 className="section-name">Acerca de nosotros</h3>
            <h4 className="section-title">
              Nosotros y la <span style={{ color: "#FFB116" }}>minería</span>
            </h4>
            <p className="section-description">
              Somos una empresa que consolida su trayectoria desarrollando
              software principalmente para el sector minero-industrial. Llevamos
              más de 6 años trabajando con la empresa minera BHP Billiton,
              ofreciendo soluciones modernas e innovadoras a problemas de
              análisis y visualización de datos, reportabilidad de turnos y
              modernización de procesos análogos.
            </p>
          </div>
          <div className="right-section">
            <img className="faces" src={squares}></img>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AboutMining;
