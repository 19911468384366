import React from "react";
import logo from "../assets/img/outlier_logo.svg";
import contactListData from "./data/contactLinkData.json";
import { Link } from "react-router-dom";

const workData = contactListData.work;
const socialData = contactListData.social;

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-content">
          <div className="pages-links">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="jobs">
              <ItemList data={workData}></ItemList>
            </div>
            <div className="social">
              <ItemList data={socialData}></ItemList>
            </div>
            <div className="contact">
              <h3 className="footer-title">Contacto</h3>
              <a href="mailto:contacto@outlier.cl" className="item contact">
                contacto@outlier.cl
              </a>
            </div>
          </div>
          <div className="location">
            <h3 className="footer-title">Estamos aquí</h3>
            <div className="map">
              <iframe
                title="Address"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.04035818724!2d-72.5949685839299!3d-38.739836095370684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9614d3e740e470b5%3A0xabbde5e36512af9c!2sAntonio%20Varas%20687%2C%20Temuco%2C%20Araucan%C3%ADa!5e0!3m2!1sen!2scl!4v1589899883944!5m2!1sen!2scl"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen={true}
                aria-hidden={true}
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

const aType = (d, k) => {
  if (d.type === "mailto")
    return (
      <a key={k} href={`mailto:${d.name}`} className="item">
        {d.name}
      </a>
    );
  if (d.type === "tel")
    return (
      <a key={k} href={`tel:${d.name}`} className="item">
        {d.name}
      </a>
    );
  if (d.type === "internal-link")
    return (
      <Link key={k} to={d.to}>
        <div key={k} className="item">
          {d.name}
        </div>
      </Link>
    );
  if (d.type === "external-link")
    return (
      <a key={k} href={`${d.to}`} className="item">
        {d.name}
      </a>
    );
};

const ItemList = ({ data }) => {
  return (
    <>
      <h3 className="footer-title">{data.title}</h3>
      {data.items.map((v, k) => aType(v, k))}
    </>
  );
};

ItemList.defaultProps = {
  data: {
    title: "title",
    items: [
      {
        name: "Link 1",
        to: "#",
        type: "internal-link",
      },
      {
        name: "Link 2",
        to: "#",
        type: "internal-link",
      },
      {
        name: "Link 3",
        to: "#",
        type: "internal-link",
      },
    ],
  },
};
