import React from "react";
import { useInView } from "react-intersection-observer";
import cl from "classnames";

const CorfoCard = ({ title, description }) => {
  const { ref, inView, entry } = useInView({ threshold: 0.5 });
  const className = cl("corfo-card zoom-in", {
    "zoom-in-active": inView,
  });
  return (
    <>
      <div ref={ref} className={className}>
        <h4 className="card-title">{title}</h4>
        <p className="card-description">{description}</p>
      </div>
    </>
  );
};

export default CorfoCard;
