import React from "react";
import database from "../assets/img/database.svg";

const DbService = () => {
  return (
    <>
      <div className="db-service">
        <div className="db-service-content">
          <div className="upper-section">
            <img src={database} alt="" />
          </div>
          <div className="lower-section">
            <h4 className="section-title">Servicio de base de datos</h4>
            <p className="section-description">
              Servicio de soporte y gestión para plataforma de Drilling Report y
              extracción y transformación de los datos de planta y mina para
              plataforma IMP. Soporte de desarrollo para la automatización de la
              validación y formateo de los datos necesarios para las plataformas
              y clientes que la requieran, incluyendo análisis según se necesite
              de la información pertinente, al mismo tiempo que se brinda apoyo
              en la generación de reportes asociados a la información de Base de
              datos y documentación del servicio.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DbService;
