import React from "react";
import Briefcase from "../assets/img/briefcase.svg";
import Tag from "./Tag.js";
import drillhole from "../assets/img/notebook_drillhole.png";
import imp from "../assets/img/notebook_imp.png";
import drilling from "../assets/img/notebook_drilling.png";

const data = [
  {
    title: "IMP",
    img: imp,
    description:
      "IMP es una Plataforma que integra información de una faena minera procedente de distintas fuentes: geología, movimiento de camiones, sensores de planta, pilas de lixiviación. Toda la información generada es conciliada en diferente gráficos que permiten evaluar de manera oportuna desviaciones en las principales variables de interés.",
    projectName: "BHP",
    tags: [
      { className: "tag-sky", label: "Web" },
      { className: "tag-green", label: "Android" },
    ],
  },
  {
    title: "Drillhole Target",
    img: drillhole,
    description:
      "Drillhole Target es una aplicación de escritorio que permite generar una campaña de sondajes de manera automática de acuerdo con distintos criterios que tienen relación con la categoría del recurso objetivo, los que son definidos y configurados por el usuario. La aplicación cuenta con un visualizador 3D para ver los resultados, presupuestos para dinero y metros de sondaje, exportaciones de la selección en formato CSV e impacto del diseño generado en la categorización del recurso.",
    projectName: "BHP",
    tags: [{ className: "tag-sky", label: "Web App" }],
  },
  {
    title: "Drilling",
    img: drilling,
    description:
      "Drilling es una solución que permite crear, revisar, aprobar o rechazar los reportes de perforación utilizando una plataforma digital. Esto soluciona los problemas de  estandarización de documentos, mejora el flujo de trabajo y la comunicación entre el personal de la minera y las empresas colaboradoras.",
    projectName: "BHP",
    tags: [
      { className: "tag-sky", label: "Web" },
      { className: "tag-green", label: "Android" },
    ],
  },
];

const MiningProjectLeftImage = ({
  title,
  img,
  description,
  projectName,
  tags,
}) => {
  return (
    <>
      <div className="mining-project">
        <div className="mining-project-content">
          <div className="left-section">
            <img
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="400"
              className="mockup"
              src={img}
              alt=""
            />
          </div>
          <div className="right-section">
            <h4 className="section-title">{title}</h4>
            <p className="section-description">{description}</p>
            <div className="project-client">
              <img className="briefcase" src={Briefcase} alt="" />
              <p className="project">{projectName}</p>
            </div>
            <div className="tags">
              {tags.map(({ className, label }, k) => (
                <Tag className={className} label={label} key={k}></Tag>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MiningProjectRightImage = ({
  title,
  img,
  description,
  projectName,
  tags,
}) => {
  return (
    <>
      <div className="mining-project highlight">
        <div className="mining-project-content">
          <div className="left-section">
            <h4 className="section-title">{title}</h4>
            <p className="section-description">{description}</p>
            <div className="project-client">
              <img className="briefcase" src={Briefcase} alt="" />
              <p className="project">{projectName}</p>
            </div>
            <div className="tags">
              {tags.map(({ className, label }, k) => (
                <Tag className={className} label={label} key={k}></Tag>
              ))}
            </div>
          </div>
          <div className="right-section">
            <img
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="400"
              className="mockup"
              className="mockup"
              src={img}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

const MiningProject = () => {
  return (
    <>
      {data.map((v, k) => {
        return k % 2 === 0 ? (
          <MiningProjectLeftImage {...v} key={k} />
        ) : (
          <MiningProjectRightImage {...v} key={k} />
        );
      })}
    </>
  );
};

export default MiningProject;
