import React from "react";
import headerLinks from "../components/data/headerLinks.json";
import ThreeContainer2 from "../DarkScene.js";
import AboutMining from "../components/AboutMining";
import ContactForm from "../components/ContactForm";
import CorfoProjects from "../components/CorfoProjects.js";
import DbService from "../components/DbService";
import Footer from "../components/Footer.js";
import HeaderBar from "../components/HeaderBar.js";
import Hero from "../components/Hero.js";
import MiningProject from "../components/MiningProject";
import MiningProjectsIntro from "../components/MiningProjectsIntro.js";

const Mining = ({isExploring, onExplore}) => {
  return (
    <>
      <div className="dark">
        <HeaderBar links={headerLinks.mining}></HeaderBar>
        <Hero isExploring={isExploring} onExplore={onExplore}></Hero>
        <div id="hero-scene-dark" style={{ height: "100vh", display: "block" }}>
          <ThreeContainer2 isExploring={isExploring} />
        </div>
        <AboutMining />
        <MiningProjectsIntro />
        <MiningProject />
        <DbService />
        <CorfoProjects className="projects-dark"></CorfoProjects>
        <ContactForm></ContactForm>
        <Footer />
      </div>
    </>
  );
};

export default Mining;
