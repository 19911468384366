import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import cl from "classnames";
import squares from "../assets/img/faces.png";

const AboutUs = () => {
  const { ref, inView, entry } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (!entry) return;
    window.anime.timeline({ loop: false }).add({
      targets: "#aboutus .section-title .letter",
      translateX: [40, 0],
      translateZ: 0,
      opacity: inView ? [0, 1] : [1, 0],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (_el, i) => 350 + 40 * i,
    });
  }, [inView]);

  const className = cl("about", { "in-view": inView });

  return (
    <>
      <div ref={ref} id="aboutus" className={className}>
        <div className="about-content">
          <div className="left-section">
            <img
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="400"
              className="contact-title"
              className="faces"
              src={squares}
            ></img>
          </div>
          <div className="right-section">
            <h3 className="section-name">¿Quiénes Somos?</h3>
            <h4 className="section-title">
              <span className="letter">Un</span>{" "}
              <span className="letter">equipo</span>{" "}
              <span className="letter" style={{ color: "#05ABE8" }}>
                diferente
              </span>{" "}
              <span className="letter">es</span>{" "}
              <span className="letter">un</span>{" "}
              <span className="letter">equipo</span>{" "}
              <span className="letter" style={{ color: "#05ABE8" }}>
                creativo
              </span>
            </h4>
            <p className="section-description">
              Somos una empresa dedicada a proveer soluciones tecnológicas a
              problemas de distintas áreas productivas del país mediante el
              desarrollo de plataformas digitales y servicios de informática.
              Nuestro equipo está compuesto por una amplia gama de profesionales
              dispuestos a convertir en realidad lo que tienes en mente y
              llevarlo al próximo nivel.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
