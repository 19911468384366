import React from "react";
import Button from "./Button.js";
import Grid from "./Grid.js";

const Services = () => {
  return (
    <>
      <div id="ourservices" className="services">
        <div className="services-content">
          <div className="left-section">
            <div className="description-container">
              <h4 className="section-title">Nuestros servicios</h4>
              <p className="section-description">
                Nos dedicamos a solucionar una amplia gama de necesides
                utilizando tecnología de punta.
              </p>
              <Button
                url="#contact"
                className="button-default"
                label="¡Hablemos!"
              ></Button>
            </div>
          </div>
          <div className="right-section">
            <Grid></Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
