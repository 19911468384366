// import ThreeContainer from "./test.js";
// import HeaderBar from "./components/HeaderBar.js";
import BaseLayout from "./components/BaseLayout.js";
import { useRef } from "react";

function App() {
  const canvasRef = useRef();
  const size = { w: 200, h: 200 };
  return (
    <div className="App">
      <BaseLayout></BaseLayout>
    </div>
  );
}

export default App;
