import React, { useEffect } from "react";
import experience from "../assets/img/experience.png";
import { useInView } from "react-intersection-observer";
import cl from "classnames";

const Experience = () => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!entry) return;
    window.anime.timeline({ loop: false }).add({
      targets: "#whatwedo .section-title .letter",
      translateX: [40, 0],
      translateZ: 0,
      opacity: inView ? [0, 1] : [1, 0],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (_el, i) => 350 + 40 * i,
    });
  }, [inView]);

  const className = cl("experience", { "in-view": inView });

  return (
    <>
      <div ref={ref} id="whatwedo" className={className}>
        <div className="experience-content">
          <div className="upper-section">
            <h3 className="section-name">¿Qué hacemos?</h3>
            <h4 className="section-title">
              <span className="letter">El</span>{" "}
              <span className="letter">Software</span>{" "}
              <span className="letter">como</span>{" "}
              <span className="letter">una</span>{" "}
              <span className="letter" style={{ color: "#00C173" }}>
                experiencia
              </span>
            </h4>
            <p className="section-description">
              Olvídate de esos programas anticuados con estética windows 98 o
              plantillas genéricas sin identidad. Nosotros desarrollamos
              software enfocándonos netamente en la experiencia del usuario con
              la tecnología. Miramos los detalles como grandes oportunidades
              para destacar y nos centramos en desarrollar una estética que
              comunique más que las palabras.
            </p>
          </div>
          <div className="lower-section">
            <img src={experience} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
