import React, { useRef, useState, useEffect } from "react";
import HeaderBar from "./HeaderBar.js";
import Hero from "./Hero.js";
import ThreeContainer from "../test.js";
import AboutUs from "./AboutUs.js";
import Experience from "./Experience.js";
import MineBanner from "./MineBanner.js";
import Services from "./Services.js";
import CorfoProjects from "./CorfoProjects.js";
import ContactForm from "./ContactForm";
import Footer from "./Footer.js";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import headerLinks from "../components/data/headerLinks.json";
import Mining from "../pages/Mining.js";
import AOS from "aos";
import "aos/dist/aos.css";

const BaseLayout = (props) => {
  const canvasRef = useRef();
  const [isExploring, setIsExploring] = useState();

  function handleExplore(ev) {
    ev.preventDefault();
    const root = document.getElementById("root");
    root.classList.toggle("is-exploring");
    setIsExploring(!isExploring);
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <div id="top"></div>
      <Switch>
        {/* <Route path="/design" exact component={Mining}></Route> */}
        <Route
          path="/mining"
          exact
          render={() => (
            <Mining isExploring={isExploring} onExplore={handleExplore} />
          )}
        />

        <Route
          path="/"
          render={() => (
            <>
              <HeaderBar links={headerLinks.home}></HeaderBar>
              <Hero isExploring={isExploring} onExplore={handleExplore}></Hero>
              <div
                id="hero-scene"
                style={{
                  height: "100vh",
                  display: "block",
                  backgroundColor: "#ffffff",
                }}
              >
                <ThreeContainer
                  isExploring={isExploring}
                  canvasRef={canvasRef}
                />
              </div>
              <AboutUs></AboutUs>
              <Experience></Experience>
              <MineBanner></MineBanner>
              <Services></Services>
              <CorfoProjects></CorfoProjects>
              <ContactForm></ContactForm>
              <Footer />
            </>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default BaseLayout;
