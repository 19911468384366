import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/img/outlier_logo.svg";
import arrow from "../assets/img/arrow.svg";
import Button from "./Button.js";
import { scrollAnchors } from "../lib/scroll";
import { useHistory, useLocation } from "react-router-dom";

const html = document.getElementsByTagName("html");
const HeaderBar = (props) => {
  const [active, setActive] = useState(false);
  const navLinks = document.querySelectorAll(".scroll, .shortcuts");
  const homeref = useRef();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    navLinks.forEach((link, index) => {
      link.style.animation = active
        ? `navLinkFade 0.4s ease forwards ${index / 18 + 0.2}s`
        : "";
    });
    if (active) html[0].classList.add("overflow-hidden");
    else html[0].classList.remove("overflow-hidden");
  }, [active]);

  useEffect(() => {
    if (!homeref) return;
    homeref.current.onclick = scrollAnchors;
  }, [homeref]);

  const handleClick = (section = null) => {
    setActive(false);
  };

  function onPageChange() {
    const isMining = location.pathname === "/mining";
    window.scrollTo(0, 0);
    history.push(isMining ? "/" : "/mining");
  }

  //-------------Efecto aparicion color header------------------//
  useEffect(() => {
    const header = document.getElementById("headerbar");

    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    });
  }, []);

  const isMining = location.pathname === "/mining";

  const handleClickMenu = () => {
    setActive(!active);
    const html = document.getElementsByTagName("html");
    html[0].classList.toggle("overflow-hidden");
  };
  return (
    <>
      <div id="headerbar" className="headerbar">
        <div className="navigation">
          <div className="logo">
            {" "}
            <a ref={homeref} href="#top" onClick={() => handleClick("top")}>
              <img src={logo} alt="" />
            </a>
          </div>
          <div className={`nav-links ${active ? "nav-active" : ""}`}>
            {props.links &&
              props.links.map((link) => (
                <SimpleLink
                  key={link.url}
                  url={link.url}
                  label={link.label}
                  onClick={() => handleClick(link.action)}
                ></SimpleLink>
              ))}
            <div className="shortcuts">
              <Button
                url="#contact"
                className="button-default"
                label="¡Hablemos!"
                onClick={() => setActive(false)}
              ></Button>
              <Button
                icon={<img src={arrow}></img>}
                className="button-darkblue"
                label={isMining ? "Home" : "Minería"}
                onClick={onPageChange}
              ></Button>
            </div>
          </div>
        </div>

        <div
          className={`burguer ${active ? "toggle" : ""}`}
          onClick={() => handleClickMenu()}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
    </>
  );
};

export default HeaderBar;

function SimpleLink({ index, label, url = "#", ...rest }) {
  return (
    <a href={url} className="scroll" {...rest}>
      {label}
    </a>
  );
}
