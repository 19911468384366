import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import cl from "classnames";

const MiningProjectsIntro = () => {
  const { ref, inView, entry } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (!entry) return;
    window.anime.timeline({ loop: false }).add({
      targets: "#projects .section-title .letter",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (el, i) => 200 + 30 * i,
    });
  }, [inView]);

  const className = cl("mining-projects-intro", { "in-view": inView });
  return (
    <>
      <div ref={ref} id="projects" className={className}>
        <div className="mining-projects-intro-content">
          <div className="upper-section">
            <h3 className="section-name">Proyectos</h3>
            <h4 className="section-title">
              <span className="letter">Conoce</span>{" "}
              <span className="letter">algunos</span>{" "}
              <span className="letter">de</span>{" "}
              <span className="letter">nuestros</span>{" "}
              <span className="letter" style={{ color: "#00C9F7" }}>
                proyectos.
              </span>
            </h4>
            <p className="section-description">
              Llevamos la experiencia de usuario a otro nivel. Un software de
              administración y análisis de datos no tiene que parecer una tabla
              de excel.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiningProjectsIntro;
