import * as THREE from "three";
import Loading from '@outlier-spa/loading';
import { OrbitControls } from "../node_modules/three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "../node_modules/three/examples/jsm/loaders/GLTFLoader.js";
import {
  vertex as leafVertexShader,
  fragment as leafFragmentShader,
} from "./assets/shaders/leaf";
import {
  vertex as bandVertexShader,
  fragment as bandFragmentShader,
} from "./assets/shaders/band";
import {
  fragment as screenFragmentShader,
  vertex as screenVertexShader,
} from "./assets/shaders/screen";
import { useEffect, useRef, useState } from "react";
import TWEEN from "@tweenjs/tween.js";

const ANIMATION = TWEEN.Easing.Quadratic.InOut;
const ANIMATION_DURATION = 600;

let materialLeaf = null;
let materialBand = null;
let materialScreen = null;
let cubeTextureLoader = null;
let environmentMap = null;
let debugObject = { envMapIntensity: 1.5 };
let ambientLight = null;

/**Texture variables */
let leafTexture,
  bandTexture,
  floorShadowTexture,
  glowTexture,
  matcapGlossy,
  bayerTexture;

/**Material variables */
let basicMaterial, planeMaterial, glowMaterial;

/**
 * Funcion para carga todas las texturas
 */
function loadTextures() {
  const textureLoader = new THREE.TextureLoader();

  leafTexture = textureLoader.load("./static/textures/leaf.png");
  bandTexture = textureLoader.load("./static/textures/band.png");
  floorShadowTexture = textureLoader.load(
    "./static/textures/scene_shadow3.png"
  );
  floorShadowTexture.wrapT = THREE.RepeatWrapping;
  floorShadowTexture.repeat.y = -1;
  glowTexture = textureLoader.load("./static/textures/gradient.png");
  bayerTexture = textureLoader.load("./static/textures/bayer.png");
  bayerTexture.minFilter = THREE.NearestFilter;
  bayerTexture.magFilter = THREE.NearestFilter;
  bayerTexture.wrapS = THREE.RepeatWrapping;
  bayerTexture.wrapT = THREE.RepeatWrapping;
}

/**
 * Funcion para cargar todos los materiales
 */

function loadMaterials() {
  basicMaterial = new THREE.MeshMatcapMaterial();
  basicMaterial.matcap = matcapGlossy;

  planeMaterial = new THREE.MeshStandardMaterial({
    map: floorShadowTexture,
    transparent: true,
  });
  glowMaterial = new THREE.MeshStandardMaterial({
    map: glowTexture,
    transparent: true,
  });
}

/**
 * Funcion para cargar todos los shaders
 */
function loadShaders() {
  const leafShader = {
    vertexShader: leafVertexShader,
    fragmentShader: leafFragmentShader,
    side: THREE.DoubleSide,
  };
  materialLeaf = new THREE.ShaderMaterial({
    ...leafShader,
    uniforms: {
      uFrequency: { value: new THREE.Vector2(10, 5) },
      uTime: { value: 0 },
      uTexture: { value: leafTexture },
    },
  });

  const screenShader = {
    vertexShader: screenVertexShader,
    fragmentShader: screenFragmentShader,
    side: THREE.DoubleSide,
  };
  materialScreen = new THREE.ShaderMaterial({
    ...screenShader,
    uniforms: {
      iTime: { value: 0 },
      iResolution: {
        value: new THREE.Vector3(window.innerWidth, window.innerWidth, 1),
      },
      iChannel0: { value: bayerTexture },
    },
  });
  const bandShader = {
    vertexShader: bandVertexShader,
    fragmentShader: bandFragmentShader,
    side: THREE.DoubleSide,
  };
  materialBand = new THREE.ShaderMaterial({
    ...bandShader,
    uniforms: {
      uFrequency: { value: new THREE.Vector2(10, 5) },
      uTime: { value: 0 },
      uTexture: { value: bandTexture },
    },
  });
}

/**
 * Carga la luz ambiental
 */
function loadAmbientalLight() {
  ambientLight = new THREE.AmbientLight();
  ambientLight.color = new THREE.Color(0xffffff);
  ambientLight.intensity = 3;
}

function configuration({ scene, camera }) {
  // gui = new dat.GUI();
  /** Environment map */
  cubeTextureLoader = new THREE.CubeTextureLoader();
  camera.position.set(0, -27, 0);
  scene.add(camera);
  scene.add(ambientLight);
}

// ____________________ INIT _____________________________
function ThreeComponent({ isExploring }) {
	const loadingRef = useRef();
  let mixers = [];
  let renderer = null;
  let scene = new THREE.Scene();
  let gltfLoader = new GLTFLoader();
  let controls = null;
  let camera = null;
  let aspect = 2; // width / height
  let fov = 15;
  let near = 1;
  let far = 100;
  let game = [];

  camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const canvasRef = useRef();
  const controlsRef = useRef();
  const cameraRef = useRef(camera);

  useEffect(() => {
		loadingRef.current.setActive(true);
    if (canvasRef.current === null) return;
    const localSizes = {
      w: canvasRef.current.getBoundingClientRect().width,
      h: canvasRef.current.getBoundingClientRect().height,
    };
    sizes.width = localSizes.w;
    sizes.height = localSizes.h;
    /** Textures */
    loadTextures();

    /** Materials*/
    loadMaterials();

    /**Configuracion escena */

    /** Shader Materials */
    loadShaders();

    /** Lights */
    loadAmbientalLight();

    /** Configuracion general */
    configuration({ scene, camera });
    loadEnvironment();

    // Controls
    controls = new OrbitControls(camera, canvasRef.current);
		if (window.innerWidth < 1200) controls.target.set(0, 0, 0);
		else controls.target.set(0, 0.5, -2);

    controls.enableDamping = true;
    controls.maxPolarAngle = Math.PI * 0.44;
    controls.minPolarAngle = Math.PI * 0.3;
    controls.maxDistance = 38;
    controls.minDistance = 16;
    controls.maxAzimuthAngle = Math.PI * 1.4;
    controls.minAzimuthAngle = Math.PI * 1.2;
    controls.enablePan = true;
    controls.domElement = canvasRef.current;
    controlsRef.current = controls;

    /**
     * Renderer
     */
    renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: true,
      alpha: true,
    });

    // ---------------------------------------------------------

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    // renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.physicallyCorrectLights = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    // addGui({
    //   renderer,
    //   ambientLight,
    //   debugObject,
    //   materialLeaf,
    //   materialBand,
    //   scene
    // });
    /** Models */
    loadModel({ scene, gltfLoader, mixers, game }, () => {
			loadingRef.current.setActive(false);
		});
    renderer.setAnimationLoop((props) =>
      tick({ ...props, renderer, scene, controls, camera, mixers, game })
    );
    return () => {
      scene = null;
      camera = null;
      controls = null;
      renderer.setAnimationLoop(null);
      controlsRef.current = null;
      cameraRef.current = null;
    };
    // Scene white
  }, []);

  useEffect(() => {
    if (isExploring === void 0) return;
    const newTarget = isExploring
      ? { x: 0, y: 0, z: 0 }
      : { x: 0, y: 0.5, z: -2 };
    const newPosition = isExploring
      ? { x: -25.7, y: 10, z: -10 }
      : { x: -25.7, y: 5, z: -10 };
    new TWEEN.Tween(controlsRef.current.target)
      .easing(ANIMATION)
      .to(newTarget, ANIMATION_DURATION)
      .start();
    new TWEEN.Tween(cameraRef.current.position)
      .easing(ANIMATION)
      .to(newPosition, ANIMATION_DURATION)
      .start();
  }, [isExploring]);

  return (
		<>
			<Loading ref={loadingRef} />
			<canvas
				ref={canvasRef}
				className="webgl"
				style={{ height: "100%", width: "100%" }}
			/>
		</>
  );
}

// ____________________ FIN INIT _________________________

function resizeRendererToDisplaySize(renderer) {
  const canvas = renderer.domElement;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}

/**
 * Carga el modelo
 */
function loadModel({ scene, gltfLoader, mixers, game }, callback) {
  const outlierModelPath = "./static/models/Dark_scene.glb";

  gltfLoader.load(outlierModelPath, (gltf) => {
    gltf.scene.scale.set(1, 1, 1);
    gltf.scene.position.set(0, -1, 0);
    gltf.scene.rotation.y = Math.PI * 0.7;
    gltf.scene.traverse(function (object) {
      object.frustumCulled = false;
    });

    for (let index = 0; index < gltf.scene.children.length; index++) {
      if (gltf.scene.children[index].name === "floor_shadow")
        gltf.scene.children[index].material = planeMaterial;

      if (gltf.scene.children[index].name.indexOf("drone-body") > -1)
        game.push(gltf.scene.children[index]);

      if (gltf.scene.children[index].name.indexOf("plant-leaf") > -1)
        gltf.scene.children[index].material = materialLeaf;

      if (gltf.scene.children[index].name.indexOf("band") > -1)
        gltf.scene.children[index].material = materialBand;

      if (gltf.scene.children[index].name.indexOf("monitor") > -1)
        gltf.scene.children[index].material = materialScreen;

      if (gltf.scene.children[index].name.indexOf("screen") > -1)
        gltf.scene.children[index].material = materialScreen;
    }

    for (let index = 0; index < gltf.scene.children.length; index++) {
      if (gltf.scene.children[index].name.indexOf("cube-gradient") > -1)
        gltf.scene.children[index].material = glowMaterial;
    }

    scene.add(gltf.scene);
    updateAllMaterials({ scene });

    mixers.push(new THREE.AnimationMixer(gltf.scene));
    mixers.push(new THREE.AnimationMixer(gltf.scene));
    mixers.push(new THREE.AnimationMixer(gltf.scene));

    mixers.forEach((m, k) => {
      m.clipAction(gltf.animations[k]).play();
    });
		callback && callback();
  });
}

/**
 * Carga el skybox
 */
function loadEnvironment() {
  environmentMap = cubeTextureLoader.load([
    "./static/textures/environmentMaps/4/px.png",
    "./static/textures/environmentMaps/4/nx.png",
    "./static/textures/environmentMaps/4/py.png",
    "./static/textures/environmentMaps/4/ny.png",
    "./static/textures/environmentMaps/4/pz.png",
    "./static/textures/environmentMaps/4/nz.png",
  ]);
}

var clock = new THREE.Clock();

const updateAllMaterials = ({ scene }) => {
  scene.traverse((child) => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      child.material.envMap = environmentMap;
      child.material.envMapIntensity = debugObject.envMapIntensity;
      child.material.needsUpdate = true;
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
};

let previousTime = 0;
let elapsedTime = 0;

const tick = ({ renderer, scene, controls, camera, mixers, game }) => {
  // const elapsedTime = clock.getElapsedTime();
  elapsedTime += 0.02;

  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }
  // Update material
  materialLeaf.uniforms.uTime.value = elapsedTime;
  materialBand.uniforms.uTime.value = elapsedTime;
  materialScreen.uniforms.iTime.value = elapsedTime;

  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  if (game[0]) {
    for (let index = 0; index < game[0].children.length; index++) {
      game[0].children[index].rotation.y = 20 * elapsedTime;
    }
    game[0].position.y = 3 + Math.sin(elapsedTime) * 0.2;
    game[0].position.x = Math.sin(elapsedTime * 0.5) * 3;
    game[0].position.z =
      Math.sin(elapsedTime * 0.5) * 1 + Math.cos(elapsedTime) * 1;
    game[0].rotation.z = Math.sin(elapsedTime) * 0.2;
  }

  mixers.forEach((m) => {
    m.update(deltaTime);
  });

  TWEEN.update();

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);
};

export default ThreeComponent;

export const ThreeContainer = () => {
  const [dimension, setDimension] = useState({
    w: window.innerWidth,
    h: window.innerHeight,
  });
  const divref = useRef();
  useEffect(() => {
    if (divref.current === null) return;

    setDimension({
      w: divref.current.getBoundingClientRect().width,
      h: divref.current.getBoundingClientRect().height,
    });
    elapsedTime = 0;
    previousTime = 0;
    // console.log(elapsedTime, previousTime);
  }, [divref.current]);

  return (
    <div ref={divref} style={{ height: "100%" }}>
      <ThreeComponent width={dimension.w} height={dimension.h} />
    </div>
  );
};
