export const vertex = `
uniform vec2 uFrequency;
uniform float uTime;

varying vec2 vUv;
varying float vElevation;

void main()
{
	vec4 modelPosition = modelMatrix * vec4(position, 1.0);

	float elevation = sin(modelPosition.x * uFrequency.x - uTime) * 0.0;
	elevation += sin(modelPosition.y * -uFrequency.y - 20.0*uTime) * 0.02;

	modelPosition.z += elevation;

	vec4 viewPosition = viewMatrix * modelPosition;
	vec4 projectedPosition = projectionMatrix * viewPosition;

	gl_Position = projectedPosition;

	vUv = uv;
	vElevation = elevation;
}`;

export const fragment = `
uniform vec3 uColor;
uniform sampler2D uTexture;

varying vec2 vUv;
varying float vElevation;

void main()
{
    vec4 textureColor = texture2D(uTexture, vUv);
    textureColor.rgb *= vElevation * 7.0 + 1.0;
    gl_FragColor = textureColor;
    // gl_FragColor = vec4(vUv, 0.5, 1.0);
    // gl_FragColor = vec4(0.0, 0.7, 0.5, 1.0);
}`;
