import React from "react";

function Tag(props) {
  return (
    <>
      <div className={`tag ${props.className}`}>
        <p>{props.label}</p>
        {props.icon && props.icon}
      </div>
    </>
  );
}
Tag.defaultProps = { className: "", label: "-" };
export default Tag;
