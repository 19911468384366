import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import CorfoCard from "./CorfoCard.js";
import CorfoLogo from "./CorfoLogo";
import corfoproject from "../components/data/corfoProject.json";

const Projects = props => {
  return (
    <>
      <div className={`projects ${props.className}`}>
        <CorfoLogo></CorfoLogo>
        <ScrollContainer className="scroll-container wp-size">
          <div className="wrapper">
            {corfoproject.map((project, index) => {
              return <CorfoCard key={index} {...project}></CorfoCard>;
            })}
          </div>
        </ScrollContainer>
      </div>
    </>
  );
};

Projects.defaultProps = { className: "" };
export default Projects;
