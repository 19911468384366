export const vertex = `
uniform vec2 uFrequency;
uniform float uTime;

varying vec2 vUv;
varying float vElevation;

void main()
{
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);

    float elevation = sin(modelPosition.x * uFrequency.x - uTime*3.0) * 0.02;
    elevation += sin(modelPosition.y * uFrequency.y - uTime) * 0.01;

    modelPosition.y += elevation;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;

    vUv = uv;
    vElevation = elevation;
}`;

export const fragment = `
uniform vec3 uColor;
uniform sampler2D uTexture;

varying vec2 vUv;
varying float vElevation;

void main()
{
    vec4 textureColor = texture2D(uTexture, vUv);
    textureColor.rgb *= vElevation * 2.0 + 1.0;
    gl_FragColor = textureColor;
    // gl_FragColor = vec4(vUv, 0.5, 1.0);
    // gl_FragColor = vec4(0.0, 0.7, 0.5, 1.0);
}`;
