import cloud from "../../assets/animations/icon_cloud.json";
import desktop from "../../assets/animations/icon_desktop.json";
import mail from "../../assets/animations/icon_mail.json";
import mm from "../../assets/animations/icon_mm.json";
import npm from "../../assets/animations/icon_npm.json";
import out from "../../assets/animations/icon_out.json";
import service from "../../assets/animations/icon_service.json";
import smartphone from "../../assets/animations/icon_smartphone.json";
import videogame from "../../assets/animations/icon_videogame.json";
import vr from "../../assets/animations/icon_vr.json";
import web from "../../assets/animations/icon_web.json";

const cards = [
  {
    animation: cloud,
    title: "Cloud",
    description:
      "Almacena tus archivos en un lugar seguro con cuentas ilimitadas y espacio personalizado (valor gb asignados total/mes).",
  },
  {
    animation: mail,
    title: "E-mail",
    description:
      "No sigas ocupando el correo que creaste en 2006. Utiliza un e-mail con @tudominio y crea todas las cuentas que necesites (valor por gb asignados total/mes).",
  },
  {
    animation: mm,
    title: "Mattermost",
    description:
      "La comunicación es un pilar fundamental del desarrollo. Tenemos una solución de chat para equipos/organizaciones (valor por usuario/mes y almacenamiento total).",
  },
  {
    animation: out,
    title: "Outlier Admin",
    description:
      "Despliegue de aplicaciones y servicios administrados por nosotros (en forma de docker stacks), redis, rabbitmq, motores de bases de datos, etc.",
  },
  {
    animation: npm,
    title: "Repositorios privados",
    description:
      "Gestiona artefactos de desarrollo con los repositorios administrados por Outlier, paquetes npm, nuget, docker, etc.",
  },
  {
    animation: service,
    title: "Containers as a Service",
    description:
      "Potencia tu proceso de desarrollo de software usando entornos docker o kubernetes administrados por nosotros.",
  },
  {
    animation: smartphone,
    title: "Aplicaciones móviles",
    description:
      "Todos tenemos una idea de una app, llegó la hora de hacerlo realidad. Desarrollamos aplicaciones multiplataforma que pueden hacer todo lo que te imaginas (y más).",
  },
  {
    animation: desktop,
    title: "Aplicaciones de escritorio",
    description:
      "¿Quieres administrar tu negocio?, ¿llevar la contabilidad?, ¿crear una red social?. Lo que sea que necesites lo podemos llevar a Windows o MacOS, tu sólo cuéntanos.",
  },
  {
    animation: web,
    title: "Sitios Web",
    description:
      "Se nota cuando un sitio web está hecho con plantillas (¡lo siento!). No busques una solución genérica a un problema específico, nosotros desarrollamos todo a tu medida.",
  },
  {
    animation: videogame,
    title: "Videojuegos",
    description:
      "Si necesitas darle un toque divertido a tu proyecto, un videojuego es una buenísima opción. Desarrollamos juegos para diversas plataformas utilizando Unity.",
  },
  {
    animation: vr,
    title: "Realidad virtual",
    description:
      "Si dos dimensiones te parecen geniales, espera a ver lo que pueden hacer tres. Amplía tu horizonte y quédate inmerso en un mundo de infinitas posibilidades.",
  },
];

export default cards;
