import React, { useEffect } from "react";
import Button from "./Button.js";
import Lines from "./Lines";
import arrow from "../assets/img/arrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import cl from "classnames";

const MineBanner = () => {
  const history = useHistory();
  const location = useLocation();
  const isMining = location.pathname === "/mining";
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!entry || !inView) return;
    window.anime.timeline({ loop: false }).add({
      targets: "#minebanner .section-title .letter",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (el, i) => 300 + 30 * i,
    });
  }, [inView]);

  const className = cl("banner", { "in-view": inView });
  function onPageChange() {
    const isMining = location.pathname === "/mining";
    window.scrollTo(0, 0);
    history.push(isMining ? "/" : "/mining");
  }
  return (
    <>
      <div ref={ref} id="minebanner" className={className}>
        <Lines id="topographic"></Lines>
        <h4 className="section-title">
          <span className="letter">¿Ya</span> <span className="letter">te</span>{" "}
          <span className="letter">contamos</span>{" "}
          <span className="letter">que</span>{" "}
          <span className="letter">nos</span>{" "}
          <span className="letter">especializamos</span>{" "}
          <span className="letter">en</span>{" "}
          <span className="letter">software</span>{" "}
          <span className="letter">para</span>{" "}
          <span className="letter">el</span>{" "}
          <span className="letter">sector</span>{" "}
          <span className="letter">minero?</span>
        </h4>
        <p className="section-description">Conoce los proyectos que tenemos</p>
        <Button
          icon={<img src={arrow}></img>}
          className="button-darkblue"
          label={isMining ? "Home" : "Minería"}
          onClick={onPageChange}
        ></Button>
      </div>
    </>
  );
};

export default MineBanner;
